Inputmask({ mask: '99/99/9999' }).mask(document.getElementById('form-date'))
Inputmask({ mask: '99:99' }).mask(document.getElementById('form-time'))
Inputmask({ mask: '+(375) (99) 999-99-99' }).mask(
  document.getElementById('form-phone')
)
Inputmask('[9]{1,4}', { numericInput: true, placeholder: '1' }).mask(
  document.getElementById('form-count')
)

document.getElementById('form-event').onfocus = (e) => {
  e.target.placeholder = 'КОРПОРАТИВ'
}

const menu = document.getElementById('menu')
const menuOpen = document.getElementById('menu-open')
const menuClose = document.getElementById('menu-close')
menuOpen.onclick = (e) => {
  menuOpen.style.opacity = '0'
  menu.classList.toggle('hidden')
}
menuClose.onclick = (e) => {
  menuOpen.style.opacity = '1'

  menu.classList.toggle('hidden')
}
